<template>
  <div class="serch">
    <!-- 搜索意中人 -->
    <div class="search">
      <div class="title">
        <div class="biaoti">
          <p></p>
          搜索意中人
        </div>
        <div class="sousuo">
          <input
            type="text"
            v-model="input"
            placeholder="输入ID或昵称精准搜索"
          />
          <div class="tubiao" @click="entry()">
            <img src="../../assets/images/tubiao/sousuo.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 热门标签 -->
      <div class="hot">
        <span> 热门标签:</span>
        <ul>
          <li
            v-for="(item, index) in suiji"
            :key="index"
            :class="{ active: al == index }"
            @click="hot(index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <!-- 基本条件 -->
      <div class="condition">
        <span class="jbtj1">基本条件:</span>
        <!-- <search /> -->
        <div class="jbtj">
          <!-- 性别 -->
          <div class="ssex">
            <el-select class="ssex1" v-model="sex" placeholder="性别">
              <el-option label="男" :value="0"></el-option>
              <el-option label="女" :value="1"></el-option>
            </el-select>
          </div>
          <!-- 年龄 -->
          <div class="nianling" @click="nianling()">
            <p class="xuanze">
              {{ age3 }}
              <i class="el-icon-arrow-down"></i>
            </p>
            <div class="sage" ref="iSLAgee" v-show="agee">
              <p>请选择年龄</p>
              <el-select class="sage1" v-model="agemin" placeholder="不限">
                <el-option
                  v-for="item in Age"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <span>至</span>
              <el-select class="sage1" v-model="agemax" placeholder="不限">
                <el-option
                  v-for="item in Age"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <div class="sure" @click.stop="makeage()">确定</div>
            </div>
          </div>

          <!-- 地区 area -->
          <div class="sarea">
            <div id="select" @click="area" class="xuanze">
              <!-- 城市选择 -->
              <div>
                <!-- <p v-if="SelectionArea.province == null">工作地区不限</p>
                <p v-else-if="SelectionArea.city == null">
                  {{ SelectionArea.province }} - 不限
                </p>
                <p v-else-if="SelectionArea.county == null"> -->
                <p
                  v-if="
                    SelectionArea.province == null ||
                      SelectionArea.province == ''
                  "
                >
                  工作地区不限
                </p>
                <p
                  v-else-if="
                    SelectionArea.city == null || SelectionArea.province == ''
                  "
                >
                  {{ SelectionArea.province }} - 不限
                </p>
                <p
                  v-else-if="
                    SelectionArea.county == null || SelectionArea.province == ''
                  "
                >
                  <span
                    v-if="
                      SelectionArea.provinceId == '110000' ||
                        SelectionArea.provinceId == '120000' ||
                        SelectionArea.provinceId == '310000' ||
                        SelectionArea.provinceId == '500000'
                    "
                  >
                    {{ SelectionArea.province }}
                  </span>
                  <span v-else>
                    {{ SelectionArea.province }} - {{ SelectionArea.city }} -
                    不限
                  </span>
                </p>
                <span v-else>
                  <span
                    v-if="
                      SelectionArea.provinceId == '110000' ||
                        SelectionArea.provinceId == '120000' ||
                        SelectionArea.provinceId == '310000' ||
                        SelectionArea.provinceId == '500000'
                    "
                  >
                    {{ SelectionArea.province }} -
                    {{ SelectionArea.county }}
                  </span>
                  <span v-else>
                    {{ SelectionArea.province }} - {{ SelectionArea.city }} -
                    {{ SelectionArea.county }}
                  </span>
                </span>
              </div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="selectArea" ref="iSLWork" v-show="iSwork">
              <p>请选择工作地区</p>
              <Zeouwork
                :CreativeData="CreativeData"
                @workData="work"
                @workDatab="worka"
                @workDatac="workc"
                @workDatad="workd"
              />
              <div class="sure" @click.stop="makeSure">确定</div>
            </div>
          </div>

          <!-- 身高 -->
          <div class="shengao">
            <p @click="shengao()" class="xuanze">
              {{ height3 }}
              <i class="el-icon-arrow-down"></i>
            </p>
            <div class="sage" v-show="height1">
              <p>请选择身高</p>
              <el-select
                class="sage1"
                v-model="heightMin"
                placeholder="身高不限"
              >
                <el-option
                  v-for="item in height"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <span>至</span>
              <el-select
                class="sage1"
                v-model="heightMax"
                placeholder="身高不限"
              >
                <el-option
                  v-for="item in height"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <div class="sure" @click="makeheig()">确定</div>
            </div>
          </div>

          <!-- 体型 -->
          <div class="shape">
            <el-select class="shape1" v-model="shape" placeholder="体型不限">
              <el-option
                v-for="(item, index) in shapes"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 月收入 -->
          <div class="shouru" @click="incomee()">
            <p class="xuanze">
              {{ shouru1 }}
              <i class="el-icon-arrow-down"></i>
            </p>
            <div class="sr" v-show="shouru">
              <p>请选择月收入</p>
              <el-select
                class="sr1"
                v-model="monthIncomeMin"
                placeholder="不限"
              >
                <el-option
                  v-for="(item, index) in income"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <span>至</span>
              <el-select
                class="sr1"
                v-model="monthIncomeMax"
                placeholder="不限"
              >
                <el-option
                  v-for="(item, index) in income"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <div class="sure" @click.stop="makeincome()">确定</div>
            </div>
          </div>

          <!-- 学历 -->
          <div class="record">
            <el-select v-model="education" placeholder="学历不限">
              <el-option
                v-for="(item, index) in record"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- 高级搜索 -->
      <div class="senior">
        <span>高级搜索:</span>
        <div class="gjssj" v-show="gaoji1">
          <div class="zhiye" @click="openserver()">
            职业 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="jiguan" @click="openserver()">
            籍贯<i class="el-icon-arrow-down"></i>
          </div>
          <div class="zhufang" @click="openserver()">
            住房情况<i class="el-icon-arrow-down"></i>
          </div>
          <div class="maiche" @click="openserver()">
            买车情况 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="hunyin" @click="openserver()">
            婚姻情况 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="haizi" @click="openserver()">
            有没有孩子 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="xingzuo" @click="openserver()">
            星座 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="touxiang" @click="openserver()">
            有无头像 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="denglu" @click="openserver()">
            是否登录 <i class="el-icon-arrow-down"></i>
          </div>
        </div>
        <div class="gjss" v-show="gaoji">
          <div ref="zhiye">
            <el-select
              class="vocation"
              v-model="givevocation"
              placeholder="职业"
            >
              <el-option
                v-for="(item, index) in vocation"
                :key="index"
                :label="item.label"
                :value="item.value"
                :class="{ selected }"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 籍贯 -->
          <div class="sarea1" ref="jg" @click="area1">
            <div id="select" class="xuanze">
              <!-- 城市选择 -->
              <div>
                <p v-if="SelectionAreaL.province == null">籍贯</p>
                <p v-else-if="SelectionAreaL.city == null">
                  {{ SelectionAreaL.province }} - 不限
                </p>
                <p v-else-if="SelectionAreaL.county == null">
                  <span
                    v-if="
                      SelectionAreaL.provinceId == '110000' ||
                        SelectionAreaL.provinceId == '120000' ||
                        SelectionAreaL.provinceId == '310000' ||
                        SelectionAreaL.provinceId == '500000'
                    "
                  >
                    {{ SelectionAreaL.province }}
                  </span>
                  <span v-else>
                    {{ SelectionAreaL.province }} - {{ SelectionAreaL.city }} -
                    不限
                  </span>
                </p>
                <span v-else>
                  <span
                    v-if="
                      SelectionAreaL.provinceId == '110000' ||
                        SelectionAreaL.provinceId == '120000' ||
                        SelectionAreaL.provinceId == '310000' ||
                        SelectionAreaL.provinceId == '500000'
                    "
                  >
                    {{ SelectionAreaL.province }} -
                    {{ SelectionAreaL.county }}
                  </span>
                  <span v-else>
                    {{ SelectionAreaL.province }} - {{ SelectionAreaL.city }} -
                    {{ SelectionAreaL.county }}
                  </span>
                </span>
              </div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="selectArea" v-show="work2">
              <p>请选择籍贯</p>
              <Zeouwork
                @workData="Work"
                @workDatab="Worka"
                @workDatac="Workc"
                @workDatad="Workd"
              />

              <div class="sure" @click.stop="makeSure1">确定</div>
            </div>
          </div>
          <!--住房情况  -->
          <div ref="house">
            <el-select class="house" v-model="givehouse" placeholder="住房情况">
              <el-option
                v-for="(item, index) in house"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 买车情况 -->
          <div ref="car">
            <el-select class="car" v-model="givecar" placeholder="买车情况">
              <el-option
                v-for="(item, index) in car"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 婚姻情况 -->
          <div ref="marr">
            <el-select class="marr" v-model="givemarr" placeholder="婚姻状况">
              <el-option
                v-for="(item, index) in marr"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 有没有孩子 -->
          <div ref="child">
            <el-select
              class="child"
              v-model="givechild"
              placeholder="有没有孩子"
            >
              <el-option
                v-for="(item, index) in child"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 星座 -->
          <div class="record-constellation" ref="constellation">
            <el-select
              class="constellation"
              v-model="giveconstellation"
              placeholder="星座"
            >
              <el-option
                v-for="(item, index) in constellation"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 有无头像 -->
          <div class="record" ref="avatar">
            <el-select
              class="avatar"
              v-model="giveavatar"
              placeholder="有无头像"
            >
              <el-option
                v-for="(item, index) in avatar"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 登陆情况 -->
          <div class="record" ref="login">
            <el-select
              class="login"
              v-model="givelogin"
              placeholder="登陆情况"
              :class="givelogin !== '' ? 'acvter' : ''"
            >
              <el-option
                v-for="(item, index) in login"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- <gaoji /> -->
      </div>
      <!-- 搜索 -->
      <div class="btn-search" @click="cts">搜索</div>
    </div>
    <!--搜索结果  -->
    <div class="result">
      <!-- 开通会员特权 -->
      <div id="start" class="start" v-if="huiyuan" @click="jump()">
        <div class="remove avatar-box">
          <div class="blur">
            <img :src="avatarImg" alt="" />
          </div>
          <div class="tips-box">
            <div class="star-icon">
              <img src="../../assets/images/tubiao/start.png" alt="" />
            </div>
            <div class="tips">
              <p>开通 <span class="tequan">也在会员</span></p>
              <p>把自己放在搜索第一位</p>
            </div>
          </div>
        </div>
        <!-- 条件 -->
        <div class="term">
          <p class="term-p">{{ nickname }}</p>
          <div class="flag-box"></div>
        </div>
        <div class="user-info">
          <p class="data">
            <span>{{ activeage }}岁</span>
            <span v-if="workAddrCityName">{{ workAddrCityName }}</span>
            <span v-if="userHeight">{{ userHeight }}cm</span>
          </p>
        </div>
        <p class="seek">{{ ta }}很适合你哦,赶紧去看看吧!</p>
      </div>
      <!-- 置顶 -->
      <div
        id="start"
        class="start"
        v-for="(item, index) in searchdata"
        :key="index"
        @click="dianji(item)"
      >
        <!-- @click="dianji(item)" -->
        <div class="remove">
          <img :src="item.Img" alt="" />
          <!-- <img :src="ossL(avatar)" alt=""> -->
        </div>
        <!-- 会员昵称 -->
        <div class="term">
          <div class="term-p">
            {{
              item.nickName.substring(0, 6) +
                (item.nickName.length > 5 ? "..." : "")
            }}
          </div>
          <div class="flag-box">
            <img
              v-if="item.isVipMember == 1"
              src="../../assets/images/tubiao/yezai_member.png"
              alt=""
            />
            <img
              v-if="item.realAuthStatus == 2"
              src="../../assets/images/tubiao/yezai_auth.png"
              alt=""
            />
          </div>
        </div>
        <!-- 会员情况 -->
        <div class="user-info">
          <p class="data">
            <!-- 年龄 -->
            <span>{{ item.age }}岁</span>
            <!-- 城市 -->
            <span v-if="item.workAddrCityName">{{
              item.workAddrCityName
            }}</span>
            <!-- 身高 -->
            <span v-if="item.height">{{ item.height }}cm</span>
            <!-- 学历 -->
            <span v-if="item.education"
              >{{ item.education == 0 ? "高中及以下" : ""
              }}{{ item.education == 1 ? "中专" : ""
              }}{{ item.education == 2 ? "大专" : "" }}
              {{ item.education == 3 ? "大学本科" : "" }}
              {{ item.education == 4 ? "硕士" : "" }}
              {{ item.education == 5 ? "博士" : "" }}</span
            >
            <span v-if="item.occBigCategory">{{ item.occBigCategory }}</span>
          </p>
        </div>
        <!-- 内心独白  -->
        <p class="seek">
          <span class="ni">{{ item.introduce }}</span>
        </p>
      </div>
    </div>
    <!-- tankuang -->
    <div class="sqfw" v-show="server">
      <div class="card">
        <div class="title">
          <p>开通也在会员</p>
          <img
            @click="Children()"
            src="../../assets/images/tubiao/close.d79c63e.gif"
            alt=""
          />
        </div>
        <p class="qr">开通也在会员即可使用更多搜索条件哦!</p>
        <div class="btn" @click="jump()">开通会员</div>
      </div>
    </div>
    <!-- 筛选提醒 -->
    <div class="remind" v-show="showremind">
      <div class="tubiao">
        <img src="../../assets/images/tubiao/nolist.png" alt="" />
      </div>
      <div class="wenzi">暂无合适的用户,调整下筛选条件吧</div>
    </div>
  </div>
</template>

<script>
import {
  getScrollHeight,
  getScrollTop,
  getWindowHeight,
} from "../../api/lazyload/screen";
import { Information, tags, tag } from "@/api/my/my.js";
import Zeouwork from "@/components/zeouWork";
import { infocur } from "@/api/login/login.js";
import local from "@/api/common/local.js";
import { ossL } from "@/api/common/oss/ossL.js";
export default {
  data() {
    return {
      iSsex: true,
      showremind: false,
      shouru: false,
      height1: false,
      agee: false,
      gaoji1: true,
      gaoji: false,
      nickname: "",
      CreativeData: "",
      activeaccount: "",
      activeage: "",
      introduce: "",
      sex: "",
      givesex: "",
      Age: [],
      Age1: [],
      age3: "年龄不限",
      agemin: "",
      agemax: "",
      height: [],
      heightMin: "",
      heightMax: "",
      height3: "身高不限",
      monthIncomeMin: "",
      monthIncomeMax: "",
      shouru1: "月收入不限",
      shapes: [
        { label: "体型不限", value: null },
        { label: "保密", value: 0 },
        { label: "一般", value: 1 },
        { label: "瘦长", value: 2 },
        { label: "运动员型", value: 3 },
        { label: "比较胖", value: 4 },
        { label: "体形魁梧", value: 5 },
        { label: "壮实", value: 6 },
        { label: "苗条", value: 7 },
        { label: "高大美丽", value: 8 },
        { label: "丰满", value: 9 },
        { label: "富线条美", value: 10 },
      ],
      shape: "",
      income: [
        { label: "不限", value: null },
        { label: "3000", value: 3000 },
        { label: "5000", value: 5000 },
        { label: "8000", value: 8000 },
        { label: "10000", value: 10000 },
        { label: "12000", value: 12000 },
        { label: "15000", value: 15000 },
        { label: "50000", value: 50000 },
      ],
      giveminincome: null,
      givemaxincome: null,
      record: [
        { label: "学历不限", value: null },
        { label: "高中及以下", value: 0 },
        { label: "中专", value: 1 },
        { label: "大专", value: 2 },
        { label: "大学本科", value: 3 },
        { label: "硕士", value: 4 },
        { label: "博士", value: 5 },
      ],
      education: "",

      vocation: [
        { label: "职业不限", value: null },
        { label: "计算机/互联网", value: "计算机/互联网" },
        { label: "销售", value: "销售" },
        { label: "客户服务", value: "客户服务" },
        { label: "通信/电子", value: "通信/电子" },
        { label: "生产/制造", value: "生产/制造" },
        { label: "物流/仓储", value: "物流/仓储" },
        { label: "商贸/采购", value: "商贸/采购" },
        { label: "人事/行政", value: "人事/行政" },
        { label: "高级管理", value: "高级管理" },
        { label: "广告/市场", value: "广告/市场" },
        { label: "生物/制药", value: "生物/制药" },
        { label: "医疗/护理", value: "医疗/护理" },
        { label: "金融/银行/保险", value: "金融/银行/保险" },
        { label: "建筑/房地产", value: "建筑/房地产" },
        { label: "咨询/顾问", value: "咨询/顾问" },
        { label: "法律", value: "法律" },
        { label: "财会/审计", value: "财会/审计" },
        { label: "教育/科研", value: "教育/科研" },
        { label: "服务业", value: "服务业" },
        { label: "交通运输", value: "交通运输" },
        { label: "政府机构", value: "政府机构" },
        { label: "军人/警察", value: "军人/警察" },
        { label: "农林牧渔", value: "农林牧渔" },
        { label: "自由职业", value: "自由职业" },
        { label: "在校学生", value: "在校学生" },
        { label: "待业", value: "待业" },
        { label: "其他职业", value: "其他职业" },
      ],
      givevocation: "",
      house: [
        { label: "住房不限", value: null },
        { label: "和家人同住", value: "0" },
        { label: "已购房", value: "1" },
        { label: "租房", value: "2" },
        { label: "打算婚后购房", value: "3" },
        { label: "住在单位宿舍", value: "4" },
      ],
      givehouse: "",
      car: [
        { label: "买车不限", value: null },
        { label: "已买车", value: "0" },
        { label: "未买车", value: "1" },
      ],
      givecar: "",
      marr: [
        { label: "婚姻不限", value: null },
        { label: "未婚", value: "0" },
        { label: "离异", value: "1" },
        { label: "丧偶", value: "2" },
      ],
      givemarr: "",
      child: [
        { label: "有没有孩子不限", value: null },
        { label: "没有孩子", value: "0" },
        { label: "有孩子且住在一起", value: "1" },
        { label: "有孩子偶尔会在一起住", value: "2" },
        { label: "有孩子但不在身边", value: "3" },
      ],
      givechild: "",
      constellation: [
        { label: "星座不限", value: null },
        { label: "牡羊座(03.21-04.20)", value: "0" },
        { label: "金牛座(04.21-05.20)", value: "1" },
        { label: "双子座(05.21-06.21)", value: "2" },
        { label: "巨蟹座(06.22-07.22)", value: "3" },
        { label: "狮子座(07.23-08.22)", value: "4" },
        { label: "处女座(08.23-09.22)", value: "5" },
        { label: "天秤座(09.23-10.22)", value: "6" },
        { label: "天蝎座(10.23-11.21)", value: "7" },
        { label: "射手座(11.22-12.21)", value: "8" },
        { label: "摩羯座(12.22-01.19)", value: "9" },
        { label: "水瓶座(01.20-02.19)", value: "10" },
        { label: "双鱼座(02.20-03.20)", value: "11" },
      ],
      giveconstellation: "",
      avatar: [
        { label: "头像不限", value: null },
        { label: "有", value: "0" },
        { label: "无", value: "1" },
      ],
      giveavatar: "",
      login: [
        { label: "登录情况不限", value: null },
        { label: "已登陆", value: "0" },
        { label: "未登录", value: "1" },
      ],
      givelogin: "",
      cl: null,
      al: null,
      list: ["猜你喜欢", "会员专区"],
      mongolia: false,
      data1: [],
      // src: require("../../assets/images/message/tou.png"),
      iSwork: false,
      work2: false,
      getprovince: "请选择工作城市",
      getcity: "",
      getcounty: "",
      getprovince1: "",
      getcity1: "",
      getcounty1: "",
      // page: 0,
      // limit: 10,
      Total: "",
      pages: "",
      pageNum: 1,
      current: 1,
      pageTotal: 0,
      flag: true,
      searchdata: [],
      city: true,
      server: false,
      selectcity: "",
      selectcity1: [],
      ta: "",
      id: "",
      nickName: "",
      input: "",
      huiyuan: false,
      selected: false,
      suiji: [{ name: "猜你喜欢" }, { name: "会员专区" }],
      jiguan: true,
      dataList: {},
      SelectionArea: "",
      SelectionAreaL: "",
      workAddrCityName: "",
      avatarImg: "",
      //当前用户信息
      userSex: "",
      userHeight: "",
      //测试
      ManShap: [
        {
          value: null,
          label: "体型不限",
        },
        {
          value: 0,
          label: "保密",
        },
        {
          value: 1,
          label: "一般",
        },
        {
          value: 2,
          label: "瘦长",
        },
        {
          value: 3,
          label: "运动员型",
        },
        {
          value: 4,
          label: "比较胖",
        },
        {
          value: 5,
          label: "体格魁梧",
        },
        {
          value: 6,
          label: "壮实",
        },
      ],
      WomenShap: [
        {
          value: null,
          label: "体型不限",
        },
        {
          value: 0,
          label: "保密",
        },
        {
          value: 1,
          label: "一般",
        },
        {
          value: 2,
          label: "瘦长",
        },
        {
          value: 7,
          label: "苗条",
        },
        {
          value: 8,
          label: "高大美丽",
        },
        {
          value: 9,
          label: "丰满",
        },
        {
          value: 10,
          label: "富线条美",
        },
      ],
      /**
       * 择偶
       */
      memberMateSelectionAge: "",
      memberMateSelectionHeight: "",
      memberMateSelectionMonthIncome: "",
    };
  },
  created() {
    this.getxinxi();
    this.hottags();
    this.age();
    this.heig();
  },

  mounted() {
    window.addEventListener("scroll", this.load);
    // this.load(1, 10);
    let _this = this;
    document.addEventListener("mouseup", (e) => {
      let tree = _this.$refs.iSLWork;
      if (tree) {
        if (!tree.contains(e.target)) {
          _this.iSwork = false;
        }
      }
    });
  },
  methods: {
    // 跳转记录
    async dianji(item) {
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: item.id,
        },
      });
      window.open(rel.href, "_blank");
    },
    openserver() {
      this.server = !this.server;
    },
    Children() {
      this.server = !this.server;
    },
    workd(item) {
      this.SelectionArea = item;
    },
    //籍贯
    Workd(item) {
      this.SelectionAreaL = item;
    },
    workc(item) {
      // debugger;
      this.SelectionArea = item;
    },
    Workc(item) {
      this.SelectionAreaL = item;
    },
    worka(item) {
      this.SelectionArea = item;
    },
    Worka(item) {
      this.SelectionAreaL = item;
    },
    work(item) {
      this.SelectionArea = item;
    },
    Work(item) {
      this.SelectionAreaL = item;
    },
    // 根据随机热门标签(一组三个)
    async hottags() {
      let Data = {
        remark: 0,
        source: 0,
        num: 3,
      };
      const { code, data } = await tags(Data);
      if (code == 0) {
        this.suiji = this.suiji.concat(data);
      }
    },
    // 热门标签
    async hot(index) {
      this.al = index;
      this.searchdata = [];
      let Data = {
        flag: this.suiji[index].name,
        pageNum: 1,
        pageSize: 200,
      };
      console.log(Data);
      const { code, data } = await tag(Data);
      if (code == 0) {
        data.records.map(async (item) => {
          /**
           * 测试
           */
          const that = this;

          if (!item.msAgeMax) {
            if (!item.msAgeMin) {
              that.memberMateSelectionAge = "";
            } else {
              that.memberMateSelectionAge = item.msAgeMax + "岁以上";
            }
          } else {
            if (!item.msAgeMin) {
              that.memberMateSelectionAge = item.msAgeMax + "岁以下";
            } else {
              that.memberMateSelectionAge =
                item.msAgeMax == item.msAgeMin
                  ? `${item.msAgeMax}岁`
                  : `${item.msAgeMin}-${item.msAgeMax}岁`;
            }
          }

          if (!item.msHeightMax) {
            if (!item.msHeightMin) {
              that.memberMateSelectionHeight = "";
            } else {
              that.memberMateSelectionHeight = item.msHeightMin + "cm以上";
            }
          } else {
            if (!item.msHeightMin) {
              that.memberMateSelectionHeight = item.msHeightMax + "cm以下";
            } else {
              that.memberMateSelectionHeight =
                item.msHeightMin + "-" + item.msHeightMax + "cm";
            }
          }

          if (!item.msMonthIncomeMax) {
            if (!item.msMonthIncomeMin) {
              that.memberMateSelectionMonthIncome = "";
            } else {
              that.memberMateSelectionMonthIncome =
                item.msMonthIncomeMin + "元以上";
            }
          } else {
            if (!item.msMonthIncomeMin) {
              that.memberMateSelectionMonthIncome =
                item.msMonthIncomeMax + "元以下";
            } else {
              that.memberMateSelectionMonthIncome =
                item.msMonthIncomeMin + "-" + item.msMonthIncomeMax + "元";
            }
          }

          if (item.introduce) {
            console.log("存在");
          } else if (
            that.memberMateSelectionAge ||
            that.memberMateSelectionHeight ||
            that.memberMateSelectionMonthIncome
          ) {
            item.introduce = `我正在寻找${
              item.msWorkAddrProvinceName
                ? item.msWorkAddrCityName
                  ? item.msWorkAddrProvinceName == item.msWorkAddrCityName
                    ? item.msWorkAddrProvinceName
                    : item.msWorkAddrProvinceName + item.msWorkAddrCityName
                  : item.msWorkAddrProvinceName
                : item.workAddrProvinceName == item.workAddrCityName
                ? item.workAddrProvinceName
                : item.workAddrProvinceName + item.workAddrCityName
            }${
              that.memberMateSelectionAge
                ? ",年龄在" + that.memberMateSelectionAge
                : ""
            }${
              that.memberMateSelectionHeight
                ? ",身高在" + that.memberMateSelectionHeight
                : ""
            }${
              that.memberMateSelectionMonthIncome
                ? ",月薪" + that.memberMateSelectionMonthIncome
                : ""
            }的${item.sex == "0" ? "女性" : "男性"}`;
          } else {
            if (item.sex == "0") {
              item.introduce = `我正在寻找${
                item.workAddrProvinceName
                  ? item.workAddrCityName
                    ? item.workAddrProvinceName == item.workAddrCityName
                      ? item.workAddrProvinceName
                      : item.workAddrProvinceName + item.workAddrCityName
                    : item.workAddrProvinceName
                  : "附近"
              },年龄在${item.age - 7 >= 18 ? item.age - 7 : 18}-${
                item.age + 3 >= 99 ? 99 : item.age + 3
              }岁的${item.sex == "0" ? "女性" : "男性"}`;
            } else {
              item.introduce = `我正在寻找${
                item.workAddrProvinceName == item.workAddrCityName
                  ? item.workAddrProvinceName
                  : item.workAddrProvinceName + item.workAddrCityName
              },年龄在${item.age - 3 >= 18 ? item.age - 3 : 18}-${
                item.age + 7 >= 99 ? 99 : item.age + 7
              }岁的${item.sex == "0" ? "女性" : "男性"}`;
            }
          }
          /**
           * end
           */
          this.searchdata.push(
            Object.assign({}, item, {
              Img: ossL(item.avatar),
              memberMateSelection: {
                ageMax: item.msAgeMax,
                ageMin: item.msAgeMin,
                workAddrCityName: item.msWorkAddrCityName,
              },
            })
          );
        });
        console.log("标签", this.searchdata);
        if (this.UisVipMember == 0) {
          this.huiyuan = true;
          if (this.searchdata.length == 0) {
            this.huiyuan = false;
          } else {
            this.huiyuan = true;
          }
        } else {
          this.huiyuan = false;
        }
        // if (this.searchdata.length == 0) {
        //   this.huiyuan = false;
        // }
      }
    },
    //初始化推荐数据    // 根据条件筛选
    async cts() {
      console.log("搜索");
      this.searchdata = [];
      let Agemin,
        Agemax,
        HeightMax,
        HeightMin,
        MonthIncomeMax,
        MonthIncomeMin,
        Shape,
        OccBigCategory,
        HouseStatus;

      if (this.agemin == "不限") {
        Agemin = null;
      } else {
        Agemin = this.agemin;
      }
      if (this.agemax == "不限") {
        Agemax = null;
      } else {
        Agemax = this.agemax;
      }
      //收入处理
      if (this.monthIncomeMin == "不限") {
        MonthIncomeMin = null;
      } else {
        MonthIncomeMin = this.monthIncomeMin;
      }
      if (this.monthIncomeMax == "不限") {
        MonthIncomeMax = null;
      } else {
        MonthIncomeMax = this.monthIncomeMax;
      }
      if (this.heightMax == "不限") {
        HeightMax = null;
      } else {
        HeightMax = this.heightMax;
      }
      if (this.heightMin == "不限") {
        HeightMin = null;
      } else {
        HeightMin = this.heightMin;
      }

      if (this.shape == -1) {
        Shape = null;
      } else {
        Shape = this.shape;
      }
      if (this.givevocation == -1) {
        OccBigCategory = null;
      } else {
        OccBigCategory = this.givevocation;
      }
      if (this.givehouse == -1) {
        HouseStatus = null;
      } else {
        HouseStatus = this.givehouse;
      }
      let Data = {
        sex: this.sex,
        shape: Shape,
        education: this.education,
        //工作地区
        workAddrCityId: this.SelectionArea.cityId,
        workAddrCityName: this.SelectionArea.city,
        workAddrDistrictId: this.SelectionArea.countyId,
        workAddrDistrictName: this.SelectionArea.county,
        workAddrProvinceId: this.SelectionArea.provinceId,
        workAddrProvinceName: this.SelectionArea.province,
        //籍贯
        nativePlaceCityId: this.SelectionAreaL.cityId,
        nativePlaceCityName: this.SelectionAreaL.city,
        nativePlaceDistrictId: this.SelectionAreaL.countyId,
        nativePlaceDistrictName: this.SelectionAreaL.county,
        nativePlaceProvinceId: this.SelectionAreaL.provinceId,
        nativePlaceProvinceName: this.SelectionAreaL.province,
        //-----------------
        buyCarStatus: this.givecar,
        occBigCategory: OccBigCategory,
        status: this.givelogin,
        maritalStatus: this.givemarr,
        houseStatus: HouseStatus,
        existsChildren: this.givechild,
        constellatory: this.giveconstellation,
        ageMin: Agemin,
        ageMax: Agemax,
        heightMax: HeightMax,
        heightMin: HeightMin,
        // monthIncomeMin: this.monthIncomeMin,
        monthIncomeMin: MonthIncomeMin,
        // monthIncomeMax: this.monthIncomeMax,
        monthIncomeMax: MonthIncomeMax,
        pageNum: this.pageNum,
        pageSize: 200,
        // UisVipMember: "",
      };
      const { code, data } = await Information(Data);
      if (code == 0) {
        this.searchdata = [];
        data.records.map(async (item) => {
          /**
           * 测试
           */
          const that = this;

          if (!item.memberMateSelection.ageMax) {
            if (!item.memberMateSelection.ageMin) {
              that.memberMateSelectionAge = "";
            } else {
              that.memberMateSelectionAge =
                item.memberMateSelection.ageMin + "岁以上";
            }
          } else {
            if (!item.memberMateSelection.ageMin) {
              that.memberMateSelectionAge =
                item.memberMateSelection.ageMax + "岁以下";
            } else {
              that.memberMateSelectionAge =
                item.memberMateSelection.ageMin ==
                item.memberMateSelection.ageMax
                  ? `${item.memberMateSelection.ageMax}岁`
                  : `${item.memberMateSelection.ageMin}-${item.memberMateSelection.ageMax}岁`;
            }
          }

          if (!item.memberMateSelection.heightMax) {
            if (!item.memberMateSelection.heightMin) {
              that.memberMateSelectionHeight = "";
            } else {
              that.memberMateSelectionHeight =
                item.memberMateSelection.heightMin + "cm以上";
            }
          } else {
            if (!item.memberMateSelection.heightMin) {
              that.memberMateSelectionHeight =
                item.memberMateSelection.heightMax + "cm以下";
            } else {
              that.memberMateSelectionHeight =
                item.memberMateSelection.heightMin +
                "-" +
                item.memberMateSelection.heightMax +
                "cm";
            }
          }

          if (!item.memberMateSelection.monthIncomeMax) {
            if (!item.memberMateSelection.monthIncomeMin) {
              that.memberMateSelectionMonthIncome = "";
            } else {
              that.memberMateSelectionMonthIncome =
                item.memberMateSelection.monthIncomeMin + "元以上";
            }
          } else {
            if (!item.memberMateSelection.monthIncomeMin) {
              that.memberMateSelectionMonthIncome =
                item.memberMateSelection.monthIncomeMax + "元以下";
            } else {
              that.memberMateSelectionMonthIncome =
                item.memberMateSelection.monthIncomeMin +
                "-" +
                item.memberMateSelection.monthIncomeMax +
                "元";
            }
          }
          console.log(
            "=====",
            that.memberMateSelectionAge,
            that.memberMateSelectionHeight,
            that.memberMateSelectionMonthIncome
          );
          if (item.introduce) {
            console.log("存在");
          } else if (
            that.memberMateSelectionAge ||
            that.memberMateSelectionHeight ||
            that.memberMateSelectionMonthIncome
          ) {
            console.log(
              "择偶",
              that.memberMateSelectionMonthIncome,
              that.memberMateSelectionAge,
              that.memberMateSelectionHeight
            );
            item.introduce = `我正在寻找${
              item.memberMateSelection.workAddrProvinceName
                ? item.memberMateSelection.workAddrCityName
                  ? item.memberMateSelection.workAddrProvinceName ==
                    item.memberMateSelection.workAddrCityName
                    ? item.memberMateSelection.workAddrProvinceName
                    : item.memberMateSelection.workAddrProvinceName +
                      item.memberMateSelection.workAddrCityName
                  : item.memberMateSelection.workAddrProvinceName
                : item.workAddrProvinceName == item.workAddrCityName
                ? item.workAddrProvinceName
                : item.workAddrProvinceName + item.workAddrCityName
            }${
              that.memberMateSelectionAge
                ? ",年龄在" + that.memberMateSelectionAge
                : ""
            }${
              that.memberMateSelectionHeight
                ? ",身高在" + that.memberMateSelectionHeight
                : ""
            }${
              that.memberMateSelectionMonthIncome
                ? ",月薪" + that.memberMateSelectionMonthIncome
                : ""
            }的${item.sex == "0" ? "女性" : "男性"}`;
          } else {
            if (item.sex == "0") {
              item.introduce = `我正在寻找${
                item.workAddrProvinceName
                  ? item.workAddrCityName
                    ? item.workAddrProvinceName == item.workAddrCityName
                      ? item.workAddrProvinceName
                      : item.workAddrProvinceName + item.workAddrCityName
                    : item.workAddrProvinceName
                  : "附近"
              },年龄在${item.age - 7 >= 18 ? item.age - 7 : 18}-${
                item.age + 3 >= 99 ? 99 : item.age + 3
              }岁的${item.sex == "0" ? "女性" : "男性"}`;
            } else {
              item.introduce = `我正在寻找${
                item.workAddrProvinceName == item.workAddrCityName
                  ? item.workAddrProvinceName
                  : item.workAddrProvinceName + item.workAddrCityName
              },年龄在${item.age - 3 >= 18 ? item.age - 3 : 18}-${
                item.age + 7 >= 99 ? 99 : item.age + 7
              }岁的${item.sex == "0" ? "女性" : "男性"}`;
            }
          }
          /**
           * end
           */
          this.searchdata.push(
            Object.assign({}, item, {
              Img: ossL(item.avatar),
            })
          );
        });
        // this.searchdata = data.records
        this.Total = data.total;
        // this.searchdata.pages;

        if (this.UisVipMember == 0) {
          this.huiyuan = true;
          if (this.searchdata.length == 0) {
            this.huiyuan = false;
          } else {
            this.huiyuan = true;
          }
        } else {
          this.huiyuan = false;
        }
      }
    },
    // 根据ID和网名筛选
    async entry() {
      var reg = /^\s*$/g;
      if (this.input == "" || reg.test(this.input) || this.input == null) {
        this.$message({
          message: "请输入正确的ID或昵称",
          type: "error",
          offset: 100,
        });
      } else {
        const { code, data } = await Information({
          cond: this.input,
        });
        if (code == 0) {
          this.searchdata = [];
          data.records.map(async (item) => {
            /**
             * 测试
             */
            const that = this;

            if (!item.memberMateSelection.ageMax) {
              if (!item.memberMateSelection.ageMin) {
                that.memberMateSelectionAge = "";
              } else {
                that.memberMateSelectionAge =
                  item.memberMateSelection.ageMin + "岁以上";
              }
            } else {
              if (!item.memberMateSelection.ageMin) {
                that.memberMateSelectionAge =
                  item.memberMateSelection.ageMax + "岁以下";
              } else {
                that.memberMateSelectionAge =
                  item.memberMateSelection.ageMin ==
                  item.memberMateSelection.ageMax
                    ? `${item.memberMateSelection.ageMax}岁`
                    : `${item.memberMateSelection.ageMin}-${item.memberMateSelection.ageMax}岁`;
              }
            }

            if (!item.memberMateSelection.heightMax) {
              if (!item.memberMateSelection.heightMin) {
                that.memberMateSelectionHeight = "";
              } else {
                that.memberMateSelectionHeight =
                  item.memberMateSelection.heightMin + "cm以上";
              }
            } else {
              if (!item.memberMateSelection.heightMin) {
                that.memberMateSelectionHeight =
                  item.memberMateSelection.heightMax + "cm以下";
              } else {
                that.memberMateSelectionHeight =
                  item.memberMateSelection.heightMin +
                  "-" +
                  item.memberMateSelection.heightMax +
                  "cm";
              }
            }

            if (!item.memberMateSelection.monthIncomeMax) {
              if (!item.memberMateSelection.monthIncomeMin) {
                that.memberMateSelectionMonthIncome = "";
              } else {
                that.memberMateSelectionMonthIncome =
                  item.memberMateSelection.monthIncomeMin + "元以上";
              }
            } else {
              if (!item.memberMateSelection.monthIncomeMin) {
                that.memberMateSelectionMonthIncome =
                  item.memberMateSelection.monthIncomeMax + "元以下";
              } else {
                that.memberMateSelectionMonthIncome =
                  item.memberMateSelection.monthIncomeMin +
                  "-" +
                  item.memberMateSelection.monthIncomeMax +
                  "元";
              }
            }
            console.log(
              "=====",
              that.memberMateSelectionAge,
              that.memberMateSelectionHeight,
              that.memberMateSelectionMonthIncome
            );
            if (item.introduce) {
              console.log("存在");
            } else if (
              that.memberMateSelectionAge ||
              that.memberMateSelectionHeight ||
              that.memberMateSelectionMonthIncome
            ) {
              console.log(
                "择偶",
                that.memberMateSelectionMonthIncome,
                that.memberMateSelectionAge,
                that.memberMateSelectionHeight
              );
              item.introduce = `我正在寻找${
                item.memberMateSelection.workAddrProvinceName
                  ? item.memberMateSelection.workAddrCityName
                    ? item.memberMateSelection.workAddrProvinceName ==
                      item.memberMateSelection.workAddrCityName
                      ? item.memberMateSelection.workAddrProvinceName
                      : item.memberMateSelection.workAddrProvinceName +
                        item.memberMateSelection.workAddrCityName
                    : item.memberMateSelection.workAddrProvinceName
                  : item.workAddrProvinceName == item.workAddrCityName
                  ? item.workAddrProvinceName
                  : item.workAddrProvinceName + item.workAddrCityName
              }${
                that.memberMateSelectionAge
                  ? ",年龄在" + that.memberMateSelectionAge
                  : ""
              }${
                that.memberMateSelectionHeight
                  ? ",身高在" + that.memberMateSelectionHeight
                  : ""
              }${
                that.memberMateSelectionMonthIncome
                  ? ",月薪" + that.memberMateSelectionMonthIncome
                  : ""
              }的${item.sex == "0" ? "女性" : "男性"}`;
            } else {
              if (item.sex == "0") {
                item.introduce = `我正在寻找${
                  item.workAddrProvinceName
                    ? item.workAddrCityName
                      ? item.workAddrProvinceName == item.workAddrCityName
                        ? item.workAddrProvinceName
                        : item.workAddrProvinceName + item.workAddrCityName
                      : item.workAddrProvinceName
                    : "附近"
                },年龄在${item.age - 7 >= 18 ? item.age - 7 : 18}-${
                  item.age + 3 >= 99 ? 99 : item.age + 3
                }岁的${item.sex == "0" ? "女性" : "男性"}`;
              } else {
                item.introduce = `我正在寻找${
                  item.workAddrProvinceName == item.workAddrCityName
                    ? item.workAddrProvinceName
                    : item.workAddrProvinceName + item.workAddrCityName
                },年龄在${item.age - 3 >= 18 ? item.age - 3 : 18}-${
                  item.age + 7 >= 99 ? 99 : item.age + 7
                }岁的${item.sex == "0" ? "女性" : "男性"}`;
              }
            }
            /**
             * end
             */
            this.searchdata.push(
              Object.assign({}, item, {
                Img: ossL(item.avatar),
              })
            );
          });
        }
      }
    },
    //初始化获取当前用户性别推荐相反性别
    async getxinxi() {
      console.log("页面加载数据");
      this.$store.state.index = 1;
      this.searchdata = [];
      let active = local.get("access_token");
      const { code, data } = await infocur(active);
      if (code == 0) {
        console.log(data, "获取到当前用户信息");
        this.activeaccount = data.user.account;
        this.avatarImg = ossL(data.user.avatar);
        this.activeage = data.user.age;
        this.introduce = data.user.introduce;
        this.userSex = data.user.sex;
        //区分男女
        if (Number(this.userSex) == 0) {
          this.shapes = this.WomenShap;
        } else if (Number(this.userSex) == 1) {
          this.shapes = this.ManShap;
        }
        this.userHeight = data.user.height;
        this.workAddrCityName = data.user.workAddrCityName;
        //当前用户工作地区
        this.SelectionArea = {
          province: data.user.workAddrProvinceName,
          // cityId: data.user.workAddrCityId,
          // countyId: data.user.workAddrDistrictId,
          provinceId: data.user.workAddrProvinceId,
          // city: data.user.workAddrCityName,
          // county: data.user.workAddrDistrictName,
        };
        this.nickname = data.user.nickName;
        this.givesex = Number(data.user.sex);
        this.CreativeData = {
          // workAddrCityId: data.workAddrCityId,
          // workAddrCityName: data.workAddrCityName,
          // workAddrDistrictId: data.workAddrDistrictId,
          // workAddrDistrictName: data.workAddrDistrictName,
          workAddrProvinceId: data.workAddrProvinceId,
          workAddrProvinceName: data.workAddrProvinceName,
        };
        this.UisVipMember = data.user.isVipMember;
        if (data.user.sex == 0) {
          this.ta = "她";
        } else {
          this.ta = "他";
        }
        if (data.user.isVipMember == 1) {
          this.gaoji = true;
          // this.huiyuan = false;
          this.gaoji1 = false;
        } else {
          // this.huiyuan = true;
          this.gaoji = false;
          this.gaoji1 = true;
        }
        if (this.givesex == 0) {
          this.sex = 1;
        } else {
          this.sex = 0;
        }

        //初始化数据
        let a;
        if (this.userSex == 1) {
          a = 0;
        } else {
          a = 1;
        }
        let Data = {
          sex: a,
          // shape: Shape,
          education: this.education,
          //工作地区
          // workAddrCityId: this.SelectionArea.cityId,
          // workAddrDistrictId: this.SelectionArea.countyId,
          workAddrProvinceId: this.SelectionArea.provinceId,
          //籍贯
          // nativePlaceCityId: this.SelectionAreaL.cityId,
          // nativePlaceDistrictId: this.SelectionAreaL.countyId,
          nativePlaceProvinceId: this.SelectionAreaL.provinceId,
          //-----------------
          buyCarStatus: this.givecar,
          // occBigCategory: OccBigCategory,
          status: this.givelogin,
          maritalStatus: this.givemarr,
          // houseStatus: HouseStatus,
          existsChildren: this.givechild,
          constellatory: this.giveconstellation,
          // ageMin: Agemin,
          // ageMax: Agemax,
          // heightMax: HeightMax,
          // heightMin: HeightMin,
          monthIncomeMin: this.monthIncomeMin,
          monthIncomeMax: this.monthIncomeMax,
          pageNum: this.pageNum,
          pageSize: 200,
          // UisVipMember: "",
        };
        const DD = await Information(Data);
        if (DD.code == 0) {
          // this.searchdata = data.records;
          DD.data.records.map(async (item) => {
            /**
             * 测试
             */
            const that = this;

            if (!item.memberMateSelection.ageMax) {
              if (!item.memberMateSelection.ageMin) {
                that.memberMateSelectionAge = "";
              } else {
                that.memberMateSelectionAge =
                  item.memberMateSelection.ageMin + "岁以上";
              }
            } else {
              if (!item.memberMateSelection.ageMin) {
                that.memberMateSelectionAge =
                  item.memberMateSelection.ageMax + "岁以下";
              } else {
                that.memberMateSelectionAge =
                  item.memberMateSelection.ageMin ==
                  item.memberMateSelection.ageMax
                    ? `${item.memberMateSelection.ageMax}岁`
                    : `${item.memberMateSelection.ageMin}-${item.memberMateSelection.ageMax}岁`;
              }
            }

            if (!item.memberMateSelection.heightMax) {
              if (!item.memberMateSelection.heightMin) {
                that.memberMateSelectionHeight = "";
              } else {
                that.memberMateSelectionHeight =
                  item.memberMateSelection.heightMin + "cm以上";
              }
            } else {
              if (!item.memberMateSelection.heightMin) {
                that.memberMateSelectionHeight =
                  item.memberMateSelection.heightMax + "cm以下";
              } else {
                that.memberMateSelectionHeight =
                  item.memberMateSelection.heightMin +
                  "-" +
                  item.memberMateSelection.heightMax +
                  "cm";
              }
            }

            if (!item.memberMateSelection.monthIncomeMax) {
              if (!item.memberMateSelection.monthIncomeMin) {
                that.memberMateSelectionMonthIncome = "";
              } else {
                that.memberMateSelectionMonthIncome =
                  item.memberMateSelection.monthIncomeMin + "元以上";
              }
            } else {
              if (!item.memberMateSelection.monthIncomeMin) {
                that.memberMateSelectionMonthIncome =
                  item.memberMateSelection.monthIncomeMax + "元以下";
              } else {
                that.memberMateSelectionMonthIncome =
                  item.memberMateSelection.monthIncomeMin +
                  "-" +
                  item.memberMateSelection.monthIncomeMax +
                  "元";
              }
            }
            console.log(
              "=====",
              that.memberMateSelectionAge,
              that.memberMateSelectionHeight,
              that.memberMateSelectionMonthIncome
            );
            if (item.introduce) {
              console.log("存在");
            } else if (
              that.memberMateSelectionAge ||
              that.memberMateSelectionHeight ||
              that.memberMateSelectionMonthIncome
            ) {
              console.log(
                "择偶",
                that.memberMateSelectionMonthIncome,
                that.memberMateSelectionAge,
                that.memberMateSelectionHeight
              );
              item.introduce = `我正在寻找${
                item.memberMateSelection.workAddrProvinceName
                  ? item.memberMateSelection.workAddrCityName
                    ? item.memberMateSelection.workAddrProvinceName ==
                      item.memberMateSelection.workAddrCityName
                      ? item.memberMateSelection.workAddrProvinceName
                      : item.memberMateSelection.workAddrProvinceName +
                        item.memberMateSelection.workAddrCityName
                    : item.memberMateSelection.workAddrProvinceName
                  : item.workAddrProvinceName == item.workAddrCityName
                  ? item.workAddrProvinceName
                  : item.workAddrProvinceName + item.workAddrCityName
              }${
                that.memberMateSelectionAge
                  ? ",年龄在" + that.memberMateSelectionAge
                  : ""
              }${
                that.memberMateSelectionHeight
                  ? ",身高在" + that.memberMateSelectionHeight
                  : ""
              }${
                that.memberMateSelectionMonthIncome
                  ? ",月薪" + that.memberMateSelectionMonthIncome
                  : ""
              }的${item.sex == "0" ? "女性" : "男性"}`;
            } else {
              if (item.sex == "0") {
                item.introduce = `我正在寻找${
                  item.workAddrProvinceName
                    ? item.workAddrCityName
                      ? item.workAddrProvinceName == item.workAddrCityName
                        ? item.workAddrProvinceName
                        : item.workAddrProvinceName + item.workAddrCityName
                      : item.workAddrProvinceName
                    : "附近"
                },年龄在${item.age - 7 >= 18 ? item.age - 7 : 18}-${
                  item.age + 3 >= 99 ? 99 : item.age + 3
                }岁的${item.sex == "0" ? "女性" : "男性"}`;
              } else {
                item.introduce = `我正在寻找${
                  item.workAddrProvinceName == item.workAddrCityName
                    ? item.workAddrProvinceName
                    : item.workAddrProvinceName + item.workAddrCityName
                },年龄在${item.age - 3 >= 18 ? item.age - 3 : 18}-${
                  item.age + 7 >= 99 ? 99 : item.age + 7
                }岁的${item.sex == "0" ? "女性" : "男性"}`;
              }
            }
            /**
             * end
             */
            this.searchdata.push(
              Object.assign({}, item, {
                Img: ossL(item.avatar),
              })
            );
          });
          this.Total = DD.data.total;
          if (this.UisVipMember == 0) {
            this.huiyuan = true;
            if (this.searchdata.length == 0) {
              this.huiyuan = false;
            } else {
              this.huiyuan = true;
            }
          } else {
            this.huiyuan = false;
          }
        }
      }
    },
    //无限滚动加载
    load() {
      if (getScrollTop() + getWindowHeight() >= getScrollHeight()) {
        if (this.searchdata.pageNum < this.pages) {
          //先判断下一页是否有数据
          this.searchdata.pageNum += 1; //查询条件的页码+1
        } else {
          console.log("到底了");
          // console.log('__',this.SelectionArea);
        }
      }
    },

    // 显示收入
    incomee() {
      this.shouru = !this.shouru;
      this.agee = false;
      this.height1 = false;
      this.iSwork = false;
    },
    makeincome() {
      this.shouru = false;
      //--
      if (this.monthIncomeMax == "" && this.monthIncomeMin == "") {
        this.shouru1 = "月收入不限";
      } else if (
        this.monthIncomeMax == "不限" &&
        this.monthIncomeMin !== "不限"
      ) {
        this.shouru1 = `${this.monthIncomeMin} 至 不限`;
      } else if (
        this.monthIncomeMin == "不限" &&
        this.monthIncomeMax !== "不限"
      ) {
        this.shouru1 = `不限 至 ${this.monthIncomeMax}`;
      } else if (
        this.monthIncomeMin == "不限" &&
        this.monthIncomeMax == "不限"
      ) {
        this.shouru1 = "月收入不限";
      } else {
        this.shouru1 =
          this.monthIncomeMin == "不限" && this.monthIncomeMax == "不限"
            ? "月收入不限"
            : `${this.monthIncomeMin} 至 ${this.monthIncomeMax}`;
      }
    },
    // 显示年龄
    nianling() {
      this.agee = !this.agee;
      this.shouru = false;
      this.iSwork = false;
      this.height1 = false;
      this.work2 = false;
    },
    // 基础资料年龄选择
    makeage() {
      this.agee = false;
      // console.log("年龄1111", this.agemax, this.agemin);
      if (this.agemax == "" && this.agemin == "") {
        this.age3 = "年龄不限";
      } else if (this.agemax == "不限" && this.agemin !== "不限") {
        this.age3 = `${this.agemin}岁 至 不限`;
      } else if (this.agemin == "不限" && this.agemax !== "不限") {
        this.age3 = `不限 至 ${this.agemax}岁`;
      } else if (this.agemin == "不限" && this.agemax == "不限") {
        this.age3 = "年龄不限";
      } else {
        this.age3 =
          this.agemin == "不限" && this.agemax == "不限"
            ? "年龄不限"
            : `${this.agemin} 至 ${this.agemax}岁`;
      }
    },
    // 显示身高
    shengao() {
      this.height1 = !this.height1;
      this.shouru = false;
      this.iSwork = false;
      this.agee = false;
      this.work2 = false;
    },
    makeheig() {
      this.height1 = false;
      //---
      if (this.heightMax == "" && this.heightMin == "") {
        this.height3 = "身高不限";
      } else if (this.heightMax == "不限" && this.heightMin !== "不限") {
        this.height3 = `${this.agemin}cm 至 不限`;
      } else if (this.heightMin == "不限" && this.heightMax !== "不限") {
        this.height3 = `不限 至 ${this.heightMax}cm`;
      } else {
        this.height3 =
          this.heightMin == "不限" && this.heightMax == "不限"
            ? "身高不限"
            : `${this.heightMin} 至 ${this.heightMax}cm`;
      }
      //---
    },
    // 显示城市
    area() {
      this.iSwork = !this.iSwork;
      this.work2 = false;
      this.shouru = false;
      this.height1 = false;
      this.agee = false;
    },
    area1() {
      this.iSwork = false;
      this.work2 = true;
      this.shouru = false;
      this.height1 = false;
      this.agee = false;
    },
    makeSure() {
      this.iSwork = false;
    },
    makeSure1() {
      this.work2 = false;
    },
    // 身高
    heig() {
      for (let a = 143, j = 1; a <= 211; a++, j++) {
        this.height.push({ label: a, value: j });
      }
      this.height.unshift({ label: "不限", value: 0 });
    },
    // 年龄
    age() {
      for (let i = 18, j = 1; i <= 99; i++, j++) {
        this.Age.push({ label: i, value: j });
      }
      this.Age.unshift({ label: "不限", value: 0 });
      // console.log(this.Age, "年龄");
    },
    select() {
      for (let i = 18; i < 99; i++) this.age.push(i);
    },

    jump() {
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_blank");
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.load, false);
  },
  watch: {
    searchdata(newValue) {
      if (newValue.length == 0) {
        this.showremind = true;
        // this.huiyuan = false;
      } else {
        this.showremind = false;
        // this.huiyuan = true;
      }
    },
    givevocation(newValue) {
      if (newValue != "") {
        this.$refs.zhiye.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.zhiye.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    SelectionAreaL(newValue) {
      if (newValue != "") {
        this.$refs.jg.style.background = "rgba(250, 220, 221, 0.5)";
        this.$refs.jg.style.color = "#333";
      }
    },
    givehouse(newValue) {
      if (newValue != "") {
        this.$refs.house.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.house.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    givecar(newValue) {
      if (newValue != "") {
        this.$refs.car.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.car.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    givemarr(newValue) {
      if (newValue != "") {
        this.$refs.marr.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.marr.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    givechild(newValue) {
      if (newValue != "") {
        this.$refs.child.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.child.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    giveconstellation(newValue) {
      if (newValue != "") {
        this.$refs.constellation.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.constellation.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    giveavatar(newValue) {
      if (newValue != "") {
        this.$refs.avatar.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.avatar.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    givelogin(newValue) {
      if (newValue != "") {
        this.$refs.login.firstElementChild.firstElementChild.firstElementChild.style.background =
          "rgba(250, 220, 221, 0.5)";
        this.$refs.login.firstElementChild.firstElementChild.firstElementChild.style.color =
          "#333";
      }
    },
    agemin(newValue) {
      if (newValue >= this.agemax) {
        this.agemax = newValue;
      }
    },
    agemax(newValue) {
      if (newValue <= this.agemin) {
        this.agemin = newValue;
      }
    },
    heightMax(newValue) {
      if (newValue <= this.heightMin) {
        this.heightMin = newValue;
      }
    },
    heightMin(newValue) {
      if (newValue >= this.heightMax) {
        this.heightMax = newValue;
      }
    },
    monthIncomeMax(newValue) {
      if (newValue <= this.monthIncomeMin) {
        this.monthIncomeMin = newValue;
      }
    },
    monthIncomeMin(newValue) {
      if (newValue >= this.monthIncomeMax) {
        this.monthIncomeMax = newValue;
      }
    },
    // userSex(newValue) {
    //   if (Number(newValue) == 0) {
    //     // this.shapes = this.shapes.slice(0,this.shapes.length - 5);
    //     this.shapes = this.WomenShap;
    //     console.log('数据女')
    //   } else if (Number(newValue) == 1) {
    //     //  this.shapes = this.shapes.slice(0,this.shapes.length - 5);
    //     // console.log('数据1241',this.shapes);
    //     this.shapes = this.ManShap;
    //      console.log('数据男')
    //   }
    // },
    sex(newValue) {
      if (Number(newValue) == 0) {
        this.shapes = this.ManShap;
        this.shape = null;
        console.log("数据女");
      } else if (Number(newValue) == 1) {
        this.shapes = this.WomenShap;
        this.shape = null;
        console.log("数据男");
      }
    },
  },
  components: {
    Zeouwork,
  },
};
</script>

<style lang="less" scoped>
.serch {
  padding-top: 104px;
  display: flex;
  flex-direction: column;
  background: #fafafa;

  .remind {
    width: 1200px;
    height: 534px;
    margin: 0 auto;
    margin-top: 60px;

    font-size: 14px;
    color: #333333;
    line-height: 24px;
    .tubiao {
      width: 119px;
      height: 106px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .wenzi {
      text-align: center;
      margin-top: 47px;
      font-size: 14px;
      line-height: 20px;
      color: #666;
      font-weight: 700;
    }
  }
  .sqfw {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    .card {
      position: fixed;
      top: 256px;
      left: 40%;
      background: #fff;
      width: 380px;
      height: 200px;
      border-radius: 4px;
      .title {
        width: 380px;
        line-height: 50px;
        font-size: 24px;
        color: #999;
        display: flex;
        flex-direction: row;
        p {
          width: 380px;
          text-align: center;
          padding-left: 20px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          margin-top: 12px;
          cursor: pointer;
        }
      }
      .qr {
        text-align: center;
        font-size: 24px;
        margin-top: 25px;
        line-height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
      .btn {
        width: 140px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        background: #fd686e;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
        line-height: 40px;
        border-radius: 4px;
        margin-top: 20px;
      }
    }
  }
  .result {
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-bottom: 100px;
    .start:nth-child(5n) {
      margin-right: 0;
    }
    .start {
      box-sizing: border-box;
      cursor: pointer;
      width: 224px;
      height: 360px;
      background: #fff;
      border: 1px solid #ececec;
      padding: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      .seek {
        margin: 0 auto;
        margin-top: 10px;
        width: 180px;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 20px;
        .ni {
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -moz-box;
          -moz-line-clamp: 2;
          -moz-box-orient: vertical;
          word-wrap: break-word;
          word-break: break-all;
          white-space: normal;
        }
      }
      .term {
        display: flex;
        margin-bottom: 5px;
        .term-p {
          // width: 110px;
          // overflow: hidden; /*超出宽度部分的隐藏*/
          // white-space: nowrap; /*文字不换行*/
          // text-overflow: ellipsis;
          font-weight: bold;
          // height: 26px;
          // margin: 0 auto;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
        }
        .flag-box {
          // display: inline-block;
          display: flex;
          align-items: center;
          vertical-align: middle;
          margin-top: 4px;
          line-height: 23px;
          img {
            // width: 16px;
            margin-left: 5px;
            // height: 16px;
          }
        }
      }

      .user-info {
        height: 50px;
        .data {
          width: 180px;
          span {
            font-size: 12px;
            line-height: 19px;
            display: inline-block;
            margin: 4px 3px;
            padding: 0 5px;
            border-radius: 4px;
            color: #fd686e;
            // color: rgba(255, 199, 201, 1);
            // border: 1px solid #fd686e;
            border: 1px solid rgba(255, 199, 201, 1);
          }
        }
      }
      .remove {
        width: 184px;
        height: 184px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        margin: 0 auto;
        margin-bottom: 10px;
        .tequan {
          color: #fd686e;
        }
        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          //----11.10---//
          object-fit: contain;
          background-color: black;
        }
        .image {
          width: 49px;
          height: 49px;
          margin: 0 auto;
          margin-bottom: 15px;
        }
      }
      .avatar-box {
        position: relative;
        width: 184px;
        height: 184px;
        background: center center no-repeat;
        background-size: 100% 100%;
        .blur {
          position: absolute;
          top: 0;
          left: 0;
          img {
            width: 184px;
            height: 184px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            // width: 100%;
            // height: 100%;
            -webkit-filter: blur(43px);
            -moz-filter: blur(6px);
            -ms-filter: blur(6px);
            filter: blur(6px);
            filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='6') \0;
            text-align: center;
            background-color: #fff;
            opacity: 0.5;
          }
        }
        .tips-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          margin-top: 41px;
          .star-icon {
            position: absolute;
            left: 50%;
            margin-left: -24px;
            img {
              width: 48px;
              height: 48px;
            }
          }
          .tips {
            margin-top: 63px;
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            color: #333;
          }
        }
      }
      .removeL {
        background-color: #f5f5f5;
        .remove-a {
          img {
            width: 49px;
            height: 49px;
          }
        }
      }
    }
  }
  .search {
    width: 1197px;
    height: 410px;
    margin: 0 auto;
    border: 1px solid #ececec;
    padding-bottom: 36px;
    background: #fff;
    // background: pink;
    // padding-left: 40px;
    .btn-search {
      color: #fff;
      width: 346px;
      line-height: 40px;
      text-align: center;
      background: #fd686e;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 95px;
      cursor: pointer;
    }
    .senior {
      width: 1200px;
      height: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-left: 40px;
      margin-top: 20px;
      span {
        width: 70px;
        height: 20px;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
      }
      // margin-top: 30px;
      .gjssj {
        width: 1100px;
        height: 100px;
        color: #666;
        text-align: center;
        line-height: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;

        i {
          padding-right: 12px;
        }
        div {
          border-radius: 4px;
        }
        .zhiye,
        .zhufang,
        .denglu {
          height: 40px;
          width: 140px;
          background: rgba(235, 235, 235, 0.5);
          margin-right: 6px;
          cursor: pointer;
          .el-icon-arrow-down::before {
            margin-right: -55px;
          }
        }
        .jiguan {
          width: 230px;
          height: 40px;
          margin-right: 6px;
          background: rgba(235, 235, 235, 0.5);
          .el-icon-arrow-down::before {
            margin-right: -176px;
          }
        }
        .denglu {
          height: 40px;
          cursor: pointer;
          margin-top: 10px;
        }
        .maiche,
        .hunyin {
          height: 40px;
          cursor: pointer;
          width: 120px;
          margin-right: 6px;
          background: rgba(235, 235, 235, 0.5);
          .el-icon-arrow-down::before {
            margin-right: -30px;
          }
        }
        .haizi {
          height: 40px;
          cursor: pointer;
          width: 198px;
          margin-right: 6px;
          background: rgba(235, 235, 235, 0.5);
          .el-icon-arrow-down::before {
            margin-right: -70px;
          }
        }
        .xingzuo {
          height: 40px;
          cursor: pointer;
          width: 180px;
          margin-right: 6px;
          margin-top: 10px;
          background: rgba(235, 235, 235, 0.5);
          .el-icon-arrow-down::before {
            margin-right: -90px;
          }
        }
        .touxiang {
          height: 40px;
          cursor: pointer;
          width: 120px;
          background: rgba(235, 235, 235, 0.5);
          margin-top: 10px;
          margin-right: 6px;
          .el-icon-arrow-down::before {
            margin-right: -25px;
          }
        }
      }
      span {
        // width: 100px;
        line-height: 40px;
      }
      .gjss {
        width: 1100px;
        height: 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 8px;
        line-height: 40px;
        /deep/.el-input input::-webkit-input-placeholder {
          font-size: 14px;
          color: #777;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        /deep/ .el-input__inner {
          background: rgba(235, 235, 235, 0.5);
          text-align: center;
          border: none;
          font-size: 14px;
          color: #777;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        /deep/.el-input--suffix .el-input__inner {
          text-align: center;
          color: #777;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          font-size: 14px;
        }
        .sarea1 {
          cursor: pointer;
          width: 230px;
          background: rgba(235, 235, 235, 0.5);
          height: 40px;
          text-align: center;
          line-height: 40px;
          position: relative;
          margin-right: 6px;
          border-radius: 4px;
          .xuanze {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: relative;
            p {
              color: #777;
            }
            .jg {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #777;
            }
            // text-align: center;
            i {
              display: inline-block;
              color: #777;
              position: absolute;
              left: 200px;
              top: 13px;
            }
          }
          .sure {
            width: 120px;
            height: 36px;
            line-height: 30px;
            background: #fd686e;
            margin-top: 40px;
            margin-left: 90px;
            border-radius: 4px;
            color: #ffffff;
          }
          .selectArea {
            // border: 1px solid #cfcfcf;
            // width: 300px;
            // height: 180px;
            // background: #fff;
            // position: absolute;
            // padding-top: 10px;
            // top: 42px;
            // z-index: 1;
            // p {
            //   text-align: left;
            //   margin-left: 10px;
            //   font-size: 18px;
            // }

            border: 1px solid #cfcfcf;
            width: 312px;
            height: 180px;
            background: #fff;
            position: absolute;
            padding-top: 10px;
            top: 42px;
            z-index: 1;
            p {
              text-align: left;
              margin-left: 10px;
              font-size: 14px;
            }
            #myDate {
              display: flex;
              justify-content: space-between;
              margin-left: 10px;
            }
          }
        }
        // /deep/.selected {
        //   background: pink;
        // }
        .vocation {
          width: 140px;
          margin-right: 6px;
        }
        .house {
          width: 140px;
          margin-right: 6px;
        }
        .car {
          width: 120px;
          margin-right: 6px;
        }
        .marr {
          width: 120px;
          margin-right: 6px;
        }
        .child {
          width: 198px;
          margin-right: 6px;
        }
        .avatar {
          width: 120px;
          margin-right: 6px;
          margin-top: 20px;
        }
        .login {
          width: 140px;
          margin-top: 20px;
        }
        .record-constellation {
          display: flex;
          align-items: center;
          //  margin-top: 5px;
          //  margin-right: 6px;
          padding-top: 8px;
          padding-right: 6px;
          box-sizing: border-box;
          .constellation {
            // position: absolute;
            width: 180px;
            // display: flex;
            // align-items: center;
            // margin-top: 5px;
            // margin-right: 6px;
            // line-height: 70px;
          }
        }
        // .constellation {
        //   // position: absolute;
        //   width: 180px;
        //   display: flex;
        //   align-items: center;
        //   // margin-top: 5px;
        //   // margin-right: 6px;
        //   // line-height: 70px;
        // }
      }
    }
    .hot {
      width: 1200px;
      height: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      line-height: 28px;
      margin-top: 36px;
      margin-left: 40px;
      span {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
      }
      .active {
        background: #fd686e;
        border-color: #fd686e;
        color: #fff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      ul {
        margin-top: -5px;
      }
      ul > li {
        display: inline-block;

        width: 82px;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 28px;
        border: 1px solid #979797;
        color: #666;
        border-radius: 15px;
        cursor: pointer;
        text-align: center;
        margin-left: 10px;
      }
    }
    .condition {
      width: 1200px;
      height: 40px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      margin-top: 30px;
      padding-left: 40px;
      .jbtj1 {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
      }
      span {
        margin: 0 2px;
      }
      /deep/.m-datepicker .value {
        // background: rgba(250, 220, 221, 0.5);

        /deep/.serch .search .condition .m-datepicker .value {
          color: #606266;
        }
      }
      .jbtj {
        width: 1100px;
        // height: 120px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 4px;
        margin-top: -8px;

        /deep/.el-input--suffix .el-input__inner {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
        .ssex {
          width: 80px;
          margin-right: 6px;
          // .ssex-box {
          //   height: 40px;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   background: rgba(250, 220, 221, 0.5);
          //   box-sizing: border-box;
          // }
          .ssex1 {
            // border: 1px solid #cfcfcf;
            // width: 100px;
            // background-color: #fff;
            // z-index: 50;
            /deep/.el-input__inner {
              background: rgba(250, 220, 221, 0.5);
              border: none;
              // z-index:50;
            }
          }
        }
        .border {
          border: 1px solid #cfcfcf;
          border-bottom: none;
          z-index: 49;
        }

        .shengao {
          width: 136px;
          height: 40px;
          background: rgba(250, 220, 221, 0.5);
          border-radius: 4px;
          margin-right: 6px;
          line-height: 40px;
          text-align: center;
          position: relative;
          cursor: pointer;
          .xuanze {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: relative;
            // text-align: center;
            i {
              display: inline-block;
              color: #666;
              position: absolute;
              // left: 110px;
              right: 5px;
              top: 13px;
            }
          }
          .sage {
            position: absolute;
            z-index: 20;
            top: 42px;
            width: 266px;
            height: 160px;
            border: 1px solid #cfcfcf;
            border-radius: 0 4px 4px 4px;
            background: #fff;
            margin-right: 6px;
            p {
              text-align: left;
              margin-left: 18px;
            }
            .sage1 {
              width: 104px;
              /deep/.el-input__inner {
                background: rgba(235, 235, 235, 0.5);
                border: none;
              }
            }

            .sure {
              width: 120px;
              height: 36px;
              line-height: 30px;
              background: #fd686e;
              margin-top: 20px;
              margin-left: 75px;
              border-radius: 4px;
              text-align: center;
              line-height: 36px;
              color: #fff;
              cursor: pointer;
            }
          }
        }

        .nianling {
          width: 120px;
          height: 40px;
          background: rgba(250, 220, 221, 0.5);
          border-radius: 4px;
          margin-right: 6px;
          line-height: 40px;
          text-align: center;
          position: relative;
          cursor: pointer;
          .xuanze {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: relative;
            // text-align: center;
            i {
              display: inline-block;
              color: #666;
              position: absolute;
              left: 100px;
              top: 13px;
            }
          }
          .sage {
            position: absolute;
            z-index: 20;
            top: 42px;
            width: 266px;
            height: 160px;
            border: 1px solid #cfcfcf;
            border-radius: 0 4px 4px 4px;
            background: #fff;
            margin-right: 6px;
            p {
              text-align: left;
              margin-left: 18px;
            }
            .sage1 {
              width: 104px;
              /deep/.el-input__inner {
                background: rgba(235, 235, 235, 0.5);
                border: none;
              }
            }

            .sure {
              width: 120px;
              height: 36px;
              line-height: 30px;
              background: #fd686e;
              margin-top: 20px;
              margin-left: 75px;
              border-radius: 4px;
              text-align: center;
              line-height: 36px;
              color: #fff;
              cursor: pointer;
            }
          }
        }
        .shouru {
          width: 179px !important;
          height: 40px;
          background: rgba(250, 220, 221, 0.5);
          border-radius: 4px;
          margin-right: 6px;
          line-height: 40px;
          text-align: center;
          position: relative;
          cursor: pointer;
          .xuanze {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: relative;
            // text-align: center;
            i {
              display: inline-block;
              color: #777;
              position: absolute;
              left: 145px;
              top: 13px;
            }
          }
          .sr {
            position: absolute;
            z-index: 20;
            top: 42px;
            width: 266px;
            height: 160px;
            border: 1px solid #cfcfcf;
            border-radius: 0 4px 4px 4px;
            background: #fff;
            margin-right: 6px;
            p {
              text-align: left;
              margin-left: 18px;
            }
            .sr1 {
              width: 104px;
              /deep/.el-input__inner {
                background: rgba(235, 235, 235, 0.5);
                border: none;
              }
            }

            .sure {
              width: 120px;
              height: 36px;
              line-height: 30px;
              background: #fd686e;
              margin-top: 20px;
              margin-left: 75px;
              border-radius: 4px;
              text-align: center;
              line-height: 36px;
              color: #fff;
              cursor: pointer;
            }
          }
        }
        .sarea {
          cursor: pointer;
          width: 230px;
          background: rgba(250, 220, 221, 0.5);
          height: 40px;
          text-align: center;
          line-height: 40px;
          position: relative;
          margin-right: 6px;
          border-radius: 4px;
          box-sizing: border-box;
          .xuanze {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: relative;
            // text-align: center;
            i {
              display: inline-block;
              color: #777;
              position: absolute;
              left: 200px;
              top: 13px;
            }
          }
          .sure {
            width: 120px;
            height: 36px;
            line-height: 30px;
            background: #fd686e;
            margin-top: 40px;
            margin-left: 90px;
            border-radius: 4px;
            color: #ffffff;
          }
          .selectArea {
            border: 1px solid #cfcfcf;
            width: 312px;
            height: 180px;
            background: #fff;
            position: absolute;
            padding-top: 10px;
            top: 42px;
            z-index: 1;
            p {
              text-align: left;
              margin-left: 10px;
              font-size: 14px;
            }
            #myDate {
              display: flex;
              justify-content: space-between;
              margin-left: 10px;
            }
          }
        }

        .shape {
          width: 120px;
          margin-right: 6px;
          .shape1 {
            /deep/.el-input__inner {
              background: rgba(250, 220, 221, 0.5);

              border: none;
            }
          }
        }
        .take {
          width: 250px;
          margin-right: 6px;
          .take1 {
            width: 116px;
          }
        }

        .record {
          width: 120px;
          /deep/.el-input__inner {
            background: rgba(250, 220, 221, 0.5);

            border: none;
          }
        }
      }
    }
    .title {
      display: flex;
      flex-direction: row;
      // margin-top: 20px;
      height: 60px;
      // line-height: 60px;
      font-weight: 500;
      width: 1200px;
      border-bottom: 1px solid #ececec;

      .biaoti {
        padding-left: 40px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bolder;
        color: #333333;
        line-height: 22px;
        // margin-left: 10px;
        line-height: 60px;
        display: flex;
        flex-direction: row;

        p {
          margin-top: 22px;
          margin-right: 6px;
          width: 4px;
          height: 16px;
          background: #fd686e;
        }
      }
      .sousuo {
        margin-top: 11px;
        margin-left: 820px;
        width: 208px;
        height: 38px;
        background: rgba(235, 235, 235, 0.5);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        input::-webkit-input-placeholder {
          color: #adadad;
        }

        .tubiao {
          margin-top: 11px;
          margin-left: 20px;
          width: 16px;
          height: 16px;
          cursor: pointer;
          img {
            width: 100%;
          }
        }
        input {
          border: 0;
          width: 140px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;

          color: #333;
          line-height: 20px;
          margin-top: 9px;
          margin-left: 16px;
          background: rgba(235, 235, 235, -0.5);
        }
      }
    }
  }
}

// .acvter {
//   background: rgba(250, 220, 221, 0.5) !important;
//   color: rgb(51, 51, 51);
// }

/deep/ .el-icon-arrow-up:before {
  color: #777 !important;
}
/deep/ .el-select .el-input__inner:focus {
  border-color: none;
}
/deep/ .el-input__inner::-webkit-input-placeholder {
  color: #333;
}
/deep/ .el-select-dropdown__item.selected {
  color: #333;
}
</style>
